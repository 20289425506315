<template>
  <div>
    <div class="banner">
      <ul class="banner-list">
        <li class="banner-li" style="height: 600px">
          <img
            src="https://static.a6air.com/resource/banner/20210324/login_back_2.jpg"
            alt=""
            title=""
            style="width: 100%"
          />
        </li>
      </ul>
    </div>
    <div class="container" id="loginPanel">
      <div class="search-block">
        <div class="popcontext">
          <hgroup id="hgroup-booking" style="display: block">
            <article>
              <div style="height: 260px">
                <div class="panel-header" style="margin-top: 10px">
                  <ul class="panel-tabs">
<!--                    <li :class="loginType == 'PWD' ? 'active' : ''">-->
<!--                      <a href="javascript:void(0)" @click="changeTab('PWD')"-->
<!--                        >会员登录</a-->
<!--                      >-->
<!--                    </li>-->
                    <li :class="loginType == 'VALIDATECODE' ? 'active' : ''">
                      <a
                        href="javascript:void(0)"
                        @click="changeTab('VALIDATECODE')"
                        >会员登录</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="pannel-body" v-if="loginType == 'PWD'">
                  <el-row>
                    <el-col>
                      <div class="filter">
                        <div class="filter-input">
                          <el-input
                            :clearable="true"
                            ref="userName"
                            auto-complete="on"
                            v-model="loginRequest.userName"
                            placeholder="请输入手机号"
                            style="width: 370px; height: 40px !important"
                          ></el-input>
                        </div>
                        <div class="filter-input">
                          <el-input
                            :clearable="true"
                            v-model="loginRequest.pwd"
                            ref="pwd"
                            placeholder="请输入密码"
                            style="width: 370px; height: 40px !important"
                            :show-password="true"
                          ></el-input>
                        </div>
                        <div class="filter-input">
                          <el-row>
                            <el-col :span="14">
                              <el-input
                                :clearable="true"
                                v-model="loginRequest.picValidateCode"
                                ref="picValidateCode"
                                placeholder="请输入验证码"
                                style="height: 40px !important"
                              >
                                <template slot="append"></template>
                              </el-input>
                            </el-col>
                            <el-col
                              style="position: absolute; right: 0px"
                              :span="8"
                            >
                              <el-image
                                style="
                                  width: 100px;
                                  padding-top: 5px;
                                  padding-left: 10px;
                                  height: 30px;
                                "
                                :src="picValidateUrl"
                                @click="changeValidateCode"
                              ></el-image>
                              <div
                                @click="changeValidateCode"
                                class="change-btn"
                              >
                                看不清，换一张
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                        <div class="filter-input" style="text-align: center">
                          <el-link type="danger" :underline="false">{{
                            loginError
                          }}</el-link>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="pannel-body" v-if="loginType != 'PWD'">
                  <el-row>
                    <el-col>
                      <div class="filter">
                        <div class="filter-input">
                          <el-input
                            :clearable="true"
                            ref="userName"
                            v-model="loginRequest.userName"
                            placeholder="请输入手机号"
                            style="width: 370px; height: 40px !important"
                          ></el-input>
                        </div>
                        <div class="filter-input">
                          <el-row>
                            <el-col :span="16">
                              <el-input
                                :clearable="true"
                                v-model="loginRequest.picValidateCode"
                                ref="picValidateCode"
                                placeholder="请输入图形验证码"
                                style="height: 40px !important"
                              >
                                <template slot="append"></template>
                              </el-input>
                            </el-col>
                            <el-col
                              style="
                                position: absolute;
                                right: 0px;
                                width: 100px;
                              "
                            >
                              <el-image
                                style="
                                  width: 100px;
                                  padding-top: 5px;
                                  height: 30px;
                                "
                                :src="picValidateUrl"
                                @click="changeValidateCode"
                              ></el-image>
                              <div
                                @click="changeValidateCode"
                                class="change-btn"
                              >
                                看不清，换一张
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                        <div class="filter-input">
                          <el-row>
                            <el-col :span="16">
                              <el-input
                                id="phoneValidateCode"
                                ref="phoneValidateCode"
                                v-model="loginRequest.phoneValidateCode"
                                placeholder="请输入验证码"
                              >
                                <span class="form-required">*</span>
                              </el-input>
                            </el-col>
                            <el-col
                              style="
                                position: absolute;
                                right: 0px;
                                width: 100px;
                              "
                            >
                              <el-button
                                class="validate-btn"
                                @click="fetchPhoneValidateCodeHandler"
                                v-loading="callphoneloading"
                                :disabled="
                                  phoneCodeIntervalMax <= 0 ||
                                  phoneCodeIntervalMax >= 60
                                    ? false
                                    : true
                                "
                                >{{ phoneValidateCodeLabel }}</el-button
                              >
                            </el-col>
                          </el-row>
                        </div>
                        <div class="filter-input" style="text-align: center">
                          <el-link type="danger" :underline="false">{{
                            loginError
                          }}</el-link>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div style="padding-bottom:18px;">
                  <div
                      class="checkin-links agree-protocol query"
                      id="regContainer"
                  >
                      <el-checkbox v-model="checked">已阅读并同意</el-checkbox>
                      <a
                          style="text-decoration: underline;line-height: 18px;color: #4D4E4E;"
                          @click="dialogPrivacyVisible=true"
                          target="_blank"
                      >《隐私政策》</a>
                  </div>
              </div>
              <div>
                <!-- <div class="forget-btn" @click="forgetPass" style="display: none;">忘记密码</div> -->
                <el-button
                  type="danger"
                  style="width: 100%; border-radius: 5px"
                  @click="loginHandler"
                  >登 录</el-button
                >
                <div class="sign-btn">
                  <span @click="goReg">立即注册</span>
                </div>
              </div>
            </article>
          </hgroup>
        </div>
      </div>
      <div class="active-block" style="clear: both; height: 10px"></div>
    </div>
    <el-dialog title="隐私政策" :visible.sync="dialogPrivacyVisible" width="700px">
        <div
            v-html="privacyContent"
            style="height: 400px;line-height: 25px; overflow-y: scroll;overflow-x:hidden"
        ></div>
        <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="dialogPrivacyVisible = false">关闭</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import CryptoJS from "crypto-js";
import { CURRENT_USER } from "@/store/mutation-types";
import { fetchPhoneValidateCode, login,getOneByAliasCode } from "@/api/newsapi";
import { EventBus } from "@/api/event-bus.js";

export default {
  data() {
    return {
      input: "",
      loginType: "VALIDATECODE",
      loginError: " ",
      aesKey: "airtravelpcweb00",
      aesIV: "airtravel0000000",
      callphoneloading: false,
      phoneValidateCodeLabel: "获取验证码",
      validateCodeRequest: {
        phone: "",
        picValidateCode: "",
        action: "",
        sendType: "login",
      },
      phoneCodeIntervalMax: 60,
      baseValidateUrl: "/api/user/createCaptcha?random=",
      picValidateUrl: "",
      loginRequest: {
        action: "login",
        userName: "",
        pwd: "",
        picValidateCode: "",
        phoneValidateCode: "",
      },
      value: "",

      checked:false,
      dialogPrivacyVisible:false,
      privacyContent:''
    };
  },
  created() {
    this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
  },
  mounted(){
      document.title = this.$route.meta.title;
      getOneByAliasCode({ aliasCode: "privacy" }).then(res => {
        if (res.success) {
          this.privacyContent = res.data.newsContent;
        }
      });
  },
  methods: {
    aesEncrypt(data, secretKey, iv) {
      const result = CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Utf8.parse(secretKey),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return result.toString();
    },
    aesDecrypt(cipher, secretKey, iv) {
      const decrypted = CryptoJS.AES.decrypt(
        cipher,
        CryptoJS.enc.Utf8.parse(secretKey),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return CryptoJS.enc.Utf8.stringify(decrypted);
    },
    selectPannel(pannelId) {
      document.getElementById("li_booking").setAttribute("class", "");
      document.getElementById("li_aoc").setAttribute("class", "");
      document.getElementById("li_checkin").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    fetchPhoneValidateCodeHandler() {
      if (this.loginRequest.userName.length !== 11) {
        this.$message.error("手机号码校验不通过，请核实输入11位手机号");
        this.$refs.userName.focus();
        return;
      }
      if (this.loginRequest.picValidateCode.length < 4) {
        this.$message.error("请先输入图像验证码!");
        this.$refs.picValidateCode.focus();
        return;
      }
      this.validateCodeRequest.phone = this.loginRequest.userName;
      this.validateCodeRequest.picValidateCode = this.loginRequest.picValidateCode;
      this.callphoneloading = true;
      fetchPhoneValidateCode(this.validateCodeRequest).then((res) => {
        this.callphoneloading = false;
        if (res.success) {
          this.phoneCodeIntevel();
        } else {
          this.$message.error(res.errorMessage);
        }
      });
    },

    phoneCodeIntevel() {
      var that = this;
      that.phoneCodeIntervalMax = 60;
      var interval = setInterval(function () {
        var remainSecond = that.phoneCodeIntervalMax--;
        if (remainSecond <= 0) {
          clearInterval(interval);
          that.phoneValidateCodeLabel = "获取验证码";
          return;
        }
        that.phoneValidateCodeLabel = remainSecond + "秒后重新获取";
      }, 1000);
    },

    changeValidateCode() {
      this.picValidateUrl = this.baseValidateUrl + new Date().getTime();
    },
    changeTab(tab) {
      this.loginType = tab;
      this.loginError = "";
    },
    loginHandler() {
      if(!this.checked){
        this.$message.error("请阅读并勾选协议");
        return;
      }
      this.loginError = " ";
      if (this.loginRequest.picValidateCode.length <= 0) {
        this.$message.error("验证码输入不能为空");
        this.$refs.picValidateCode.focus();
        return;
      }
      if (this.loginRequest.userName.length <= 0) {
        this.$message.error("手机号输入不能为空");
        this.$refs.userName.focus();
        return;
      }
      if (this.loginType === "PWD") {
        if (this.loginRequest.pwd.length <= 0) {
          this.$message.error("密码输入不能为空");
          this.$refs.pwd.focus();
          return;
        }
      }

      if (this.loginType !== "PWD") {
        if (this.loginRequest.phoneValidateCode.length <= 0) {
          this.$message.error("手机验证码不能为空");
          this.$refs.phoneValidateCode.focus();
          return;
        }
      }

     
      this.loginRequest.encryptType = "AES";
      this.loginRequest.loginType = this.loginType;
      var loginParam = {
        action: "login",
        loginType: this.loginType,
        userName: this.loginRequest.userName,
        pwd: this.loginRequest.pwd,
        encryptType : "AES",
        picValidateCode: this.loginRequest.picValidateCode,
        phoneValidateCode: this.loginRequest.phoneValidateCode,
      };
      loginParam.pwd = this.aesEncrypt(
        this.loginRequest.pwd,
        this.aesKey,
        this.aesIV
      );
      login(loginParam).then((res) => {
        if (res.success) {
          Vue.ls.set(CURRENT_USER, res.data);
          EventBus.$emit("onlogin", "");

          var urlRedirect = Vue.ls.get("URL_REDIRECT");
          console.log("跳转地址：" + urlRedirect);
          if (urlRedirect !== null && urlRedirect !== undefined) {
            Vue.ls.remove("URL_REDIRECT");
            window.location.href = urlRedirect;
            return;
          }

          this.$router.push({
            path: "/member/orderlist",
            query: { t: new Date().getTime() },
          });
        } else {
          this.changeValidateCode();
          this.loginError = res.errorMessage;
        }
      });
    },
    goReg() {
      this.$router.push({ path: "/reg" });
    },
    forgetPass() {
      this.$router.push({ path: "/reg" });
    },
  },
};
</script>
<style scoped lang="less">
dd,
dl,
dt,
li,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}

.banner {
  position: relative;
  overflow: hidden;
  height: 600px;
  // margin-top: 1px;
}

.banner-li {
  position: absolute;
  left: 50%;
  margin-left: -960px;
  width: 1920px;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  position: absolute;
  left: 500px;
  top: -410px;
  z-index: 1000;
  width: 140px;
}

.popcontext .tab li {
  height: 90px;
  line-height: 90px;
  border-top: 1px solid #4e617f;
  background-color: #223c61;
  color: #fff;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li.active {
  border-top: 1px solid #fff;
  background-color: #fff;
  font-size: 16px;
  color: #223c61;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 430px;
  top: -500px;
  left: 550px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 5px 5px 5px 5px;
  z-index: 100;
}

.popcontext hgroup article {
  position: relative;
  height: 380px;
  padding-left: 30px;
  padding-right: 30px;
}

.discount-city li {
  display: inline-block;
  width: 90px;
  margin-left: 7px;
  padding: 5px 5px 7px 5px;
  background: #fdfdfd;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #ececec;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
}

.discount-city li.active {
  background: #f5f5f5;
  font-weight: bold;
  border: 1px solid #d6d6d6;
}

.news-block-body ul {
  margin-top: 30px;
  padding: 10px 12px;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
}

.news-block-body ul li {
  border-bottom: 1px dotted #ededed;
}

.news-block-body ul li a {
  display: block;
  padding: 10px 0;
}

.news-block-body ul li:last-child {
  border-bottom: none;
}

.news-block-body ul li a span:first-child {
  float: left;
  width: 480px;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.news-block-body .clearfix {
  display: block;
  height: 0;
  content: "";
  clear: both;
  visibility: hidden;
  overflow: hidden;
}

.commendlist ul li {
  float: left;
  margin: 10px;
  background-color: #f8f8f8;
  position: relative;
}

.commendlist ul li a {
  width: 230px;
  height: 50px;
  display: block;
  padding: 25px 20px;
  color: #adadad;
}

.commendlist ul li a dl {
  margin-bottom: 10px;
  height: 27px;
}

.commendlist ul li a dd {
  min-width: 40px;
  max-width: 110px;
  float: left;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #333;
}

.commendlist ul li a dd.center {
  min-width: 33px;
  max-width: 33px;
  margin: 0 6px;
}

.commendlist ul li a dd {
  min-width: 40px;
  max-width: 110px;
  float: left;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #333;
}

.commendlist ul li a em div {
  display: inline;
}

.commendlist ul li a em {
  position: absolute;
  right: 20px;
  top: 40px;
}

.commendlist ul li a em .e2 {
  font-size: 30px;
  color: #cb1d36;
}

.commendlist ul li a dd.center .flight-to {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 18px;
  margin-top: 5px;
  padding: 0;
  vertical-align: middle;
  background: url("../../assets/map-mark-right.png") no-repeat;
}

.panel-header {
  // border-bottom: 2px solid #e9e8e8;
  margin-top: 10px;
  // width: 400px;
}

.panel-header .panel-tabs {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
}

.panel-tabs li {
  width: 100%;
  position: relative;
  float: left;
}

.panel-tabs li a {
  position: relative;
  display: block;
  margin: auto;
  width: 80px;
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  line-height: 42px;

  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.panel-tabs .clearfix:after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  overflow: hidden;
}

.filter {
  width: 100%;
  float: left;
}

.filter .el-input {
  margin-top: 10px;
}

.el-date-editor .el-input {
  width: 100%;
}

.filter-input {
  position: relative;
  padding-top: 10px;
}

.filter-input .el-input input {
  height: 45px !important;
  line-height: 45px;
}

.panel-tabs li.active {
  &:after {
    position: absolute;
    right: 0;
    bottom: -2px;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    content: "";
    background-color: #ce212e;
  }
  a {
    color: #d70039 !important;
  }
}
.sign-btn,
.forget-btn,
.change-btn {
  color: #009ed0;
  cursor: pointer;
}
.sign-btn {
  text-align: center;
  margin-top: 10px;
}
.forget-btn {
  text-align: right;
  margin-right: 65px;
  margin-bottom: 10px;
}
.change-btn {
  text-align: left;
  font-size: 12px;
  margin-left: 10px;
}
.validate-btn {
  width: 100px;
  margin-top: 10px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d70039;
  cursor: pointer;
  border-radius: 5px;
  background: #ffffff;
  color: #4d4e4e;
}
</style>
